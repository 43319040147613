<template>
  <div>
    <home-navbar></home-navbar>
    <div class="banner-wrapper">
      <img src="/img/default-banner.png" alt="Sign up Banner" />
    </div>
    <div class="section-wrapper">
      <div class="container p-0">
        <div class="content-wrapper">
          <div class="row">
            <div class="col">
              <div class="content">
                <h1 class="page-title w-100 fs-1 text-center title">
                  Privacy Policy 個人情報保護方針
                </h1>
                <br />
                <br />
                <div class="row justify-content-center">
                  <div class="text-center col-12 col-lg-10">
                    <p>
                      企業並びに個人のお客様向けの情報管理サービを事業活動とし、社会貢献をさせて頂いております。
                    </p>
                    <br />
                    <p>
                      また、近年の情報化の進展とプライバシー意識の向上により、個人情報の適切な取り扱いが強く求められていると当社は考えております。この考え方に基づき、当社は、自らの事業内容及び規模を考慮し、下記の通り個人情報保護方針を定め、これに従うことで個人情報を適切に取扱います。
                    </p>
                    <br />
                  </div>
                </div>
                <p>
                  規範 <br />
                  個人情報の取り扱いに関する法令、国が定める指針その他の規範を遵守します。
                </p>
                <p>
                  取得 <br />
                  偽りその他不正の手段を用いず、個人情報を適正に取得します。利用目的個人情報の取扱いに際しては、利用目的をできる限り特定します。
                </p>
                <p>
                  通知 <br />
                  個人情報の取得に際し、JIS Q 15001：2017（以下、JIS Q
                  15001）の規定で定められた場合には、利用目的の通知等を行います。利用
                  特定された利用目的の達成に必要な範囲内でのみ、個人情報を適切に取り扱い、かつそのための措置を講じます。同意
                  個人情報の取得、利用目的の変更やご本人へのアクセスにおいて、JIS
                  Q 15001の規定に従い、ご本人の同意を得ます。
                </p>
                <p>
                  正確性 <br />
                  利用目的の達成に必要な範囲内において、個人情報を正確かつ最新の内容に保ちます。
                </p>
                <p>
                  安全管理 <br />
                  個人情報への不正アクセス、個人情報の漏えい、滅失またはき損などの防止及び是正その他個人情報の安全管理に必要かつ適切な措置を講じます。
                </p>
                <p>
                  従業者 <br />
                  個人情報の安全管理のために、従業者に対する必要かつ適切な監督、ならびに定期的に適切な教育を行います。
                </p>
                <p>
                  委託 <br />
                  個人情報の取り扱いを委託する場合は、十分な個人情報の保護水準を満たしているものを選定し、安全管理が図られるよう、
                  委託先に対する必要かつ適切な監督を行います。
                </p>
                <p>
                  提供 <br />
                  JIS Q
                  15001の規定に基づく場合を除き、個人情報を第三者に提供する際にはあらかじめご本人の同意を得ます。
                </p>
                <p>
                  開示 <br />
                  開示対象個人情報の開示等の求めの手続に関して、JIS Q
                  15001の規定に従い、遅滞なく対応いたします。
                </p>
                <p>
                  苦情及び相談 <br />
                  個人情報の取り扱いに関する苦情及び相談の適切かつ迅速な処理に努め、その達成に必要な体制の整備に努めます。
                </p>
                <p>
                  監査 <br />
                  私たちの個人情報保護マネジメントシステムのJIS Q
                  15001への適合状況、及び個人情報保護マネジメントシステムの運用状況を定期的に監査します。
                </p>
                <p>
                  是正 <br />
                  監査で見つかった不適合などに対する是正措置及び予防措置を確実に実施します。
                </p>
                <p>
                  見直し <br />
                  個人情報の適切な保護を維持するために、定期的に個人情報保護マネジメントシステムを見直し、継続的な改善を図ります。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<script>
// Import Custom Components
import homeNavbar from '../components/customer/Home-Navbar.vue';
import homeFooter from '../components/customer/Home-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Privacy Policy',
    };
  },

  components: {
    'home-navbar': homeNavbar,
    'home-footer': homeFooter,
  },
};
</script>

<style scoped>
header.second-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eaecf4;
  height: 100px;
  position: relative;
  top: 0;
  background-color: #fff;
  z-index: 100;
  left: 0;
  z-index: 9;
  padding: 0 40px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 3px 3px 20px #303030 12;
  -moz-box-shadow: 3px 3px 20px #303030 12;
  box-shadow: 3px 3px 20px #303030 12;
}

@media (max-width: 990px) {
  header.second-header {
    background-color: transparent !important;
    z-index: 9 !important;
    padding-left: 0;
    height: auto;
    position: relative;
    padding: 8px 18px;
  }
}

header.second-header .navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 991px) {
  header.second-header .navbar-brand img {
    height: 28px;
  }
}

.banner-wrapper img {
  width: 100%;
  height: 354px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media (max-width: 991px) {
  .banner-wrapper img {
    height: 160px;
  }
}

.section-wrapper .content-wrapper {
  padding: 120px 0 120px 0;
}

.section-wrapper .content-wrapper .content {
  margin-right: 26px;
}

.section-wrapper .content-wrapper .title {
  font-weight: 500;
}

.section-wrapper .content-wrapper p {
  color: #232222;
  font-size: 18px;
}

.section-wrapper .content-wrapper ul li {
  list-style: none;
}

@media (max-width: 576px) {
  .section-wrapper .content-wrapper {
    padding: 0;
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media (max-width: 991px) {
  .section-wrapper {
    padding: 26px 0 120px 26px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .section-wrapper .content-wrapper {
    padding: 0;
  }

  .section-wrapper .content-wrapper .title {
    text-align: left !important;
    margin: 0;
    font-size: 22px !important;
  }

  .section-wrapper .content-wrapper p,
  .section-wrapper .content-wrapper ul li {
    font-size: 14px;
  }
}
</style>
